.header-header {
  width: 100%;
  height: 44px;
  display: flex;
  z-index: 1;
  position: fixed;
  max-width: var(--dl-size-size-maxwidth);
  align-items: stretch;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  justify-content: space-between;
}
.header-nav {
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: center;
}
.header-nav1 {
  flex: 1;
  width: 1202px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.header-navlink {
  padding-left: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.header-navlink01 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink02 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink03 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink04 {
  margin-left: var(--dl-space-space-twounits);
  margin-right: 0px;
  text-decoration: none;
}
.header-navlink06 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink07 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink08 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink09 {
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.header-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.header-burger-menu {
  display: none;
}
.header-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
}
.header-icon {
  width: 24px;
  height: 24px;
}
.header-icon2 {
  width: 24px;
  height: 24px;
}
.header-icon4 {
  width: 24px;
  height: 24px;
}
.header-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.header-nav2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-image {
  height: 2rem;
}
.header-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon6 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 991px) {
  .header-header {
    height: 44px;
  }
  .header-nav1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .header-header {
    height: 30px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    background-color: var(--dl-color-gray-white);
  }
  .header-nav {
    display: none;
  }
  .header-nav1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .header-navlink {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink01 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink02 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink03 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink04 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink05 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink06 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink07 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink08 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-navlink09 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-container {
    flex: 1;
    height: 100%;
    align-self: center;
    flex-direction: row;
  }
  .header-burger-menu {
    flex: 1;
    width: auto;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .header-container1 {
    width: var(--dl-size-size-medium);
    height: 100%;
    align-self: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .header-icon {
    width: 15px;
    height: 15px;
    align-self: center;
  }
  .header-icon2 {
    width: 15px;
    height: 15px;
    align-self: center;
  }
  .header-icon4 {
    width: 15px;
    height: 15px;
    align-self: center;
  }
}
@media(max-width: 479px) {
  .header-header {
    height: 30px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: var(--dl-color-gray-white);
  }
  .header-container {
    flex: 1;
  }
  .header-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .header-container1 {
    align-items: center;
    justify-content: center;
  }
  .header-mobile-menu {
    padding: 16px;
  }
}
