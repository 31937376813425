.header3-header {
  flex: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  max-width: auto;
  min-width: auto;
  align-self: center;
  margin-top: 44px;
  min-height: auto;
  align-items: center;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(189, 195, 199) 0.00%,rgb(149, 116, 43) 100.00%);
}
.header3-nav {
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  padding: 0px;
  align-self: center;
  margin-top: 0px;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  justify-content: space-between;
}
.header3-navlink {
  display: contents;
}
.header3-profile {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.header3-image {
  flex: 1;
  width: 90px;
  height: 90px;
  align-self: stretch;
  margin-top: 0px;
  object-fit: cover;
  margin-left: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.header3-text {
  font-size: 16px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-weight: 600;
  margin-left: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  text-decoration: underline;
}
.header3-navlink01 {
  display: contents;
}
.header3-home {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.header3-image1 {
  flex: 0;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  margin-top: var(--dl-space-space-oneandhalfunits);
  object-fit: cover;
}
.header3-text1 {
  font-size: 16px;
  align-self: center;
  font-style: normal;
  margin-top: 12px;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  text-decoration: underline;
}
.header3-navlink02 {
  display: contents;
}
.header3-ingredients {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.header3-image2 {
  flex: 0;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  object-fit: cover;
}
.header3-text2 {
  font-size: 16px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  text-decoration: underline;
}
.header3-navlink03 {
  display: contents;
}
.header3-places {
  flex: 0 0 auto;
  display: flex;
  margin-left: 0px0;
  margin-right: 0px0;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.header3-image3 {
  flex: 0;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  object-fit: cover;
}
.header3-text3 {
  font-size: 16px;
  align-self: center;
  font-style: normal;
  margin-top: 26px;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  text-decoration: underline;
}
.header3-navlink04 {
  display: contents;
}
.header3-recipes {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.header3-image4 {
  flex: 0;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  margin-top: var(--dl-space-space-unit);
  object-fit: cover;
}
.header3-text4 {
  font-size: 16px;
  align-self: center;
  font-style: normal;
  margin-top: 26px;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  text-decoration: underline;
}
.header3-navlink05 {
  display: contents;
}
.header3-restaraunts {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.header3-image5 {
  flex: 0;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  object-fit: cover;
}
.header3-link {
  font-size: 16px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  text-decoration: underline;
}
.header3-navlink06 {
  display: contents;
}
.header3-upload {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.header3-image6 {
  flex: 1;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  padding: 0px;
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.header3-link1 {
  font-size: 16px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  text-transform: uppercase;
}
.header3-text5 {
  text-decoration: underline;
}
.header3-burger-menu {
  display: none;
}
.header3-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
.header3-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.header3-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header3-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header3-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header3-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header3-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header3-text8 {
  font-size: 22px;
  text-decoration: none;
}
.header3-navlink08 {
  font-size: 22px;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header3-navlink09 {
  font-size: 22px;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header3-navlink10 {
  font-size: 22px;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header3-navlink11 {
  font-size: 22px;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header3-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header3-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header3-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 991px) {
  .header3-header {
    height: 228px;
    margin-top: 0px;
  }
  .header3-nav {
    flex: 1;
    width: 100%;
    height: auto;
    position: relative;
    align-self: center;
    margin-top: 0px;
    align-items: center;
    padding-top: 0px;
    margin-right: var(--dl-space-space-halfunit);
    padding-left: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    flex-direction: row;
    padding-bottom: 0px;
    justify-content: space-between;
  }
  .header3-profile {
    align-self: flex-start;
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: 0px;
    margin-right: 0px;
  }
  .header3-image {
    width: 90px;
    height: 90px;
    margin-top: 0px;
  }
  .header3-text {
    margin-top: 4px;
  }
  .header3-home {
    align-self: flex-start;
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: 0px;
    margin-right: 0px;
  }
  .header3-image1 {
    flex: 1;
    width: 90px;
    height: 90px;
    padding: var(--dl-space-space-halfunit);
    align-self: stretch;
    margin-top: var(--dl-space-space-unit);
  }
  .header3-text1 {
    margin-top: 18px;
  }
  .header3-ingredients {
    align-self: flex-start;
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: 0px;
    margin-right: 0px;
  }
  .header3-image2 {
    flex: 1;
    width: 90px;
    height: 90px;
    padding: var(--dl-space-space-halfunit);
    align-self: stretch;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
  }
  .header3-text2 {
    margin-top: var(--dl-space-space-unit);
  }
  .header3-places {
    align-self: flex-start;
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: 0px;
    margin-right: 0px;
  }
  .header3-image3 {
    flex: 1;
    width: 90px;
    height: 90px;
    padding: var(--dl-space-space-halfunit);
    align-self: stretch;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: 0px;
  }
  .header3-text3 {
    margin-top: 14px;
  }
  .header3-recipes {
    align-self: flex-start;
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: 0px;
    margin-right: 0px;
  }
  .header3-image4 {
    flex: 1;
    width: 90px;
    height: 90px;
    padding: var(--dl-space-space-halfunit);
    align-self: stretch;
    margin-top: var(--dl-space-space-unit);
  }
  .header3-text4 {
    margin-top: 10px;
  }
  .header3-restaraunts {
    align-self: flex-start;
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: 0px;
    margin-right: 0px;
  }
  .header3-image5 {
    flex: 1;
    width: 90px;
    height: 90px;
    padding: var(--dl-space-space-halfunit);
    align-self: stretch;
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-unit);
  }
  .header3-link {
    margin-top: var(--dl-space-space-unit);
  }
  .header3-upload {
    align-self: flex-start;
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: 0px;
    margin-right: 0px;
  }
  .header3-image6 {
    flex: 1;
    width: 90px;
    height: 90px;
    padding: var(--dl-space-space-halfunit);
    align-self: stretch;
    margin-top: var(--dl-space-space-unit);
  }
  .header3-link1 {
    margin-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .header3-header {
    margin-top: 30px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header3-nav {
    display: none;
  }
  .header3-profile {
    align-items: flex-start;
    flex-direction: column;
  }
  .header3-home {
    align-items: flex-start;
    flex-direction: column;
  }
  .header3-ingredients {
    align-items: flex-start;
    flex-direction: column;
  }
  .header3-places {
    align-items: flex-start;
    flex-direction: column;
  }
  .header3-recipes {
    align-items: flex-start;
    flex-direction: column;
  }
  .header3-restaraunts {
    align-items: flex-start;
    flex-direction: column;
  }
  .header3-upload {
    align-items: flex-start;
    flex-direction: column;
  }
  .header3-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header3-icon {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    margin-right: var(--dl-space-space-unit);
  }
  .header3-nav2 {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .header3-header {
    padding: var(--dl-space-space-unit);
    margin-top: 30px;
  }
  .header3-icon {
    align-self: flex-start;
    margin-right: var(--dl-space-space-oneandhalfunits);
  }
  .header3-mobile-menu {
    padding: 16px;
  }
}
