.burger-menu-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(90deg, rgb(189, 195, 199) 0.00%,rgb(40, 104, 34) 100.00%);
}
.burger-menu-container1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.burger-menu-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.burger-menu-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.burger-menu-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.burger-menu-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.burger-menu-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.burger-menu-container2 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.burger-menu-navlink {
  display: contents;
}
.burger-menu-icon6 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.burger-menu-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}
.burger-menu-text {
  font-size: 22px;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.burger-menu-navlink1 {
  font-size: 22px;
  margin-top: var(--dl-space-space-unit);
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.burger-menu-navlink2 {
  font-size: 22px;
  margin-top: var(--dl-space-space-unit);
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.burger-menu-navlink3 {
  font-size: 22px;
  margin-top: var(--dl-space-space-unit);
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.burger-menu-navlink4 {
  font-size: 22px;
  margin-top: var(--dl-space-space-unit);
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.burger-menu-login {
  color: #fbc503;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-threeunits);
  margin-right: 0px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: 0px;
  text-decoration: none;
}
.burger-menu-register {
  padding: var(--dl-space-space-unit);
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.burger-menu-navlink5 {
  display: contents;
}
.burger-menu-container3 {
  flex: 0 0 auto;
  width: 190px;
  height: auto;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: stretch;
  margin-left: var(--dl-space-space-threeunits);
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}
.burger-menu-image {
  width: 90px;
  align-self: flex-start;
  margin-top: 0px;
  object-fit: cover;
  padding-top: var(--dl-space-space-twounits);
}
@media(max-width: 991px) {
  .burger-menu-container {
    background-image: linear-gradient(90deg, rgb(189, 195, 199) 0.00%,rgb(40, 104, 34) 100.00%);
  }
  .burger-menu-nav {
    flex-direction: column;
  }
  .burger-menu-container2 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    align-self: stretch;
    align-items: stretch;
    margin-left: var(--dl-space-space-fourunits);
    flex-direction: column;
    justify-content: space-between;
  }
  .burger-menu-icon6 {
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    margin: var(--dl-space-space-halfunit);
    padding: 0px;
  }
  .burger-menu-nav1 {
    align-self: stretch;
    align-items: stretch;
    justify-content: space-between;
  }
  .burger-menu-text {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .burger-menu-navlink1 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .burger-menu-navlink2 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .burger-menu-navlink3 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .burger-menu-navlink4 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .burger-menu-login {
    color: #fbc503;
    align-self: flex-start;
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-unit);
  }
  .burger-menu-register {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .burger-menu-container3 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-right: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .burger-menu-image {
    padding-top: 0px;
  }
}
@media(max-width: 767px) {
  .burger-menu-container1 {
    flex: 1;
    height: 100%;
  }
  .burger-menu-nav {
    flex: 1;
    height: 505px;
    align-self: stretch;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    margin-bottom: 0px;
    padding-bottom: var(--dl-space-space-fiveunits);
    justify-content: center;
    background-image: linear-gradient(90deg, rgb(189, 195, 199) 0.00%,rgb(40, 104, 34) 100.00%);
  }
  .burger-menu-container2 {
    flex: 1;
    align-self: center;
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: var(--dl-space-space-sixunits);
    margin-bottom: 0px;
  }
  .burger-menu-icon6 {
    fill: var(--dl-color-gray-black);
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-medium);
    margin: var(--dl-space-space-unit);
    align-self: flex-end;
    padding-right: 0px;
  }
  .burger-menu-nav1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex: 1;
    align-self: center;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .burger-menu-text {
    font-size: 26px;
    margin-left: 0px;
  }
  .burger-menu-navlink1 {
    font-size: 26px;
    margin-left: 0px;
  }
  .burger-menu-navlink2 {
    font-size: 26px;
    margin-left: 0px;
  }
  .burger-menu-navlink3 {
    font-size: 26px;
    margin-left: 0px;
  }
  .burger-menu-navlink4 {
    font-size: 26px;
    margin-left: 0px;
  }
  .burger-menu-login {
    color: #fbc503;
    align-self: flex-start;
    margin-top: var(--dl-space-space-twounits);
    margin-left: var(--dl-space-space-threeunits);
  }
  .burger-menu-register {
    margin-left: var(--dl-space-space-threeunits);
  }
  .burger-menu-container3 {
    align-self: flex-end;
  }
  .burger-menu-image {
    align-self: flex-start;
    margin-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .burger-menu-container1 {
    flex: 1;
    width: 100%;
    align-self: stretch;
    align-items: stretch;
    justify-content: space-between;
  }
  .burger-menu-mobile-menu {
    padding: 16px;
  }
  .burger-menu-nav {
    flex: 1;
    align-self: stretch;
    padding-bottom: var(--dl-space-space-unit);
  }
  .burger-menu-container2 {
    gap: 0;
    flex: 0;
    width: var(--dl-size-size-xlarge);
    height: 20px;
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
    padding-bottom: 0px;
    justify-content: flex-end;
  }
  .burger-menu-icon6 {
    fill: var(--dl-color-gray-black);
    height: var(--dl-size-size-medium);
    margin: var(--dl-space-space-unit);
  }
  .burger-menu-nav1 {
    flex: 0;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .burger-menu-navlink3 {
    font-size: 26px;
    text-decoration: none;
  }
  .burger-menu-navlink4 {
    font-size: 26px;
    text-decoration: none;
  }
  .burger-menu-login {
    color: #fbc503;
    margin-left: var(--dl-space-space-threeunits);
    padding-left: 0px;
  }
  .burger-menu-register {
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .burger-menu-container3 {
    align-self: flex-start;
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .burger-menu-image {
    align-self: flex-start;
    margin-top: 0px;
    margin-left: var(--dl-space-space-halfunit);
  }
}
