.feature-card-feature-card {
  border-radius: var(--dl-radius-radius-radius8);
  background-color: transparent;
  background-image: linear-gradient(90deg, rgb(174, 206, 143) 0.00%,rgb(12, 130, 99) 100.00%);
}
.feature-card-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.feature-card-image {
  width: 68px;
  height: 63px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.feature-card-heading {
  color: #ffffff;
  align-self: center;
  text-align: center;
}
.feature-card-text {
  color: #ffffff;
}
.feature-card-root-class-name12 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0.00%,rgba(0, 0, 0, 0.8) 100.00%);
}
.feature-card-root-class-name13 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name14 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name15 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name16 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0.00%,rgba(0, 0, 0, 0.8) 100.00%);
}
.feature-card-root-class-name17 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name18 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name19 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name20 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0.00%,rgba(0, 0, 0, 0.8) 100.00%);
}
.feature-card-root-class-name21 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name22 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name23 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name24 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0.00%,rgba(0, 0, 0, 0.8) 100.00%);
}
.feature-card-root-class-name25 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name26 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name27 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name28 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0.00%,rgba(0, 0, 0, 0.8) 100.00%);
}
.feature-card-root-class-name29 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name30 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.feature-card-root-class-name31 {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%);
}
@media(max-width: 767px) {
  .feature-card-feature-card {
    flex-direction: row;
  }
  .feature-card-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card-container {
    flex-direction: column;
  }
}
