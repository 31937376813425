.footer-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.footer-footer1 {
  flex-grow: 1;
  align-self: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  background-color: #916810;
}
.footer-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.footer-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-navlink {
  font-style: normal;
  font-weight: 600;
  text-decoration: underline;
}
.footer-icon-group {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: space-between;
}
.footer-link {
  display: contents;
}
.footer-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.footer-link1 {
  display: contents;
}
.footer-icon2 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.footer-link2 {
  display: contents;
}
.footer-icon4 {
  width: 24px;
  height: 24px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .footer-footer1 {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-icon {
    margin-right: var(--dl-space-space-threeunits);
  }
  .footer-icon2 {
    margin-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .footer-footer {
    align-items: flex-end;
    flex-shrink: 0;
    flex-direction: column;
  }
  .footer-footer1 {
    flex: 1;
    height: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-container {
    margin-top: 0px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-text {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-icon-group {
    margin-top: var(--dl-space-space-twounits);
    margin-right: 0px;
  }
}
@media(max-width: 479px) {
  .footer-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-text {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
