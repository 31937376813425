.videos-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.videos-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videos-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.videos-hero-heading {
  max-width: 800px;
  text-align: center;
}
.videos-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.videos-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.videos-gallery {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videos-gallery1 {
  width: 100%;
  height: 1200px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d"  "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}
.videos-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1566312235401-87b60fd5b544?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxMzEyNjgzMHw&ixlib=rb-4.0.3&q=80&w=1080");
  background-position: center;
}
.videos-container02:hover {
  transform: scale(0.98);
}
.videos-container03 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.videos-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1556911220-e15b29be8c8f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxMzEyNjgzMHw&ixlib=rb-4.0.3&q=80&w=1080");
  background-position: center;
}
.videos-container04:hover {
  transform: scale(0.98);
}
.videos-container05 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.videos-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1543826173-1beeb97525d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxMzEyNjgzMHw&ixlib=rb-4.0.3&q=80&w=1080");
  background-position: center;
}
.videos-container06:hover {
  transform: scale(0.98);
}
.videos-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1621607015551-c7c2edd53815?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxMzEyNjgzMHw&ixlib=rb-4.0.3&q=80&w=1080");
  background-position: center;
}
.videos-container07:hover {
  transform: scale(0.98);
}
.videos-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/external/spaghetti.jpg");
  background-position: center;
}
.videos-container08:hover {
  transform: scale(0.98);
}
.videos-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1554825203-68321ddde262?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxMzEyNjgzMXw&ixlib=rb-4.0.3&q=80&w=1400');
  background-position: center;
}
.videos-container09:hover {
  transform: scale(0.98);
}
.videos-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videos-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.videos-container10 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.videos-features-heading {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.videos-features-sub-heading {
  font-size: 18px;
  text-align: center;
}
.videos-container11 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}
.videos-banner {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videos-banner-heading {
  text-align: center;
}
.videos-banner-sub-heading {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.videos-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videos-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.videos-container12 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.videos-text48 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.videos-text49 {
  text-align: left;
}
.videos-container13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media(max-width: 991px) {
  .videos-hero1 {
    flex-direction: column;
  }
  .videos-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .videos-hero-heading {
    text-align: center;
  }
  .videos-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .videos-gallery1 {
    grid-gap: var(--dl-space-space-unit);
  }
  .videos-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .videos-banner-sub-heading {
    max-width: 100%;
  }
  .videos-text49 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .videos-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .videos-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .videos-gallery1 {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .videos-container03 {
    height: 220px;
  }
  .videos-container05 {
    height: 220px;
  }
  .videos-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .videos-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .videos-container11 {
    grid-template-columns: 1fr;
  }
  .videos-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .videos-banner-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .videos-faq1 {
    flex-direction: column;
  }
  .videos-container12 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .videos-text49 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .videos-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .videos-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .videos-btn-group {
    flex-direction: column;
  }
  .videos-gallery1 {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .videos-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .videos-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
